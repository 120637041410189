import React,{ useState, useEffect } from 'react';
import '../../../assets/Scss/register.css'
import { FaPhoneAlt } from 'react-icons/fa';
import developerBg from '../../../assets/images/developers-img.jpg';
import iconTired from '../../../assets/images/icon-tired-color.png';
import iconConversion from '../../../assets/images/icon-conversions-color.png';
import iconPayment from '../../../assets/images/icon-payment.png';
import TextInput from '../../molecules/input';
import { alphabatesWithSpace, isValidMobileNo, numberText } from '../../../helper/method';
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function Developers() {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [adharLinkNo, setAdharLink] = useState("")
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errEmail: "",
        errMobileno: ""
    })

    let hasError = false;

    const handleStateChange = (name, value) => {

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name, value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name, value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name, value)
        }

        if(name === "adharLinkNo"){
            let check = value
            if(numberText(check)){
                setAdharLink(check)
                handleError(name, value)
            }
        }
    }

    const handleError = (name, value) => {
 
        let allState = errors;

        if(name === "firstName"){
            if(!value.length){
                allState["errfName"] = "Enter your First name"
            }else if(value.length < 3){
                allState["errfName"] = "Name length should be 3 charector"
            }else{
                allState["errfName"] = ""
            }
        }
        if(name === "lastName"){
            if(!value.length){
                allState["errLName"] = "Enter your Last name"
            }else if(value.length < 3){
                allState["errLName"] = "Name length should be 3 charector"
            }else{
                allState["errLName"] = ""
            }
        }
        if(name === "email"){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.length){
                allState["errEmail"] = "Email is required."
            }else if(!value.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
            }else{
                allState["errEmail"] = ""
            }
        }
        if(name === "adharLinkNo"){
            if(!value.length){
                allState["errMobileno"] = " Enter your mobile number"
            }else if( value.length < 8 ){
                allState["errMobileno"] = "Please enter at least 8 characters."
            }else{
                allState["errMobileno"] = ""
            }
        }
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        let mobile = adharLinkNo.toString()
        
        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(mobile)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }
        if(!email.length){
            allState["errEmail"] = "Email is required."
            hasError = true
        }else{
            allState["errEmail"] = ""
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            alert("test")
        }
    }
    useEffect(() => {
        AOS.init({
            once: true,
          });
      }, [])
  return (
    <section>
        <div className='container'>
            <div className='AboutSec'>
            <h2>developers</h2>
            <div className='cust_sec'>
                <figure className='imageBdr'>
                    <img src={developerBg} />
                </figure>
                <ul className='bullet-list'>
                        <li>
                            <span><img src={iconTired} /></span>
                            <label>Tired of cancellations?</label>
                        </li>
                        <li>
                            <span><img src={iconConversion} /></span>
                            <label>Worried about low conversions?</label>
                        </li>
                        <li>
                            <span><img src={iconPayment} /></span>
                            <label>want higher on-the-spot down payment for your customers?</label>
                        </li>
                    </ul>
            </div>
            <p className='text-center'>We can address the above concerns with our fintech platform/solutions!</p>
            <p className='text-center'>drop your contact details or contact Prateek Jain <FaPhoneAlt />  7506640001 </p>
            
            <form className='form'>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="firstName"
                                id={"firstName"}
                                value={firstName}
                                errorMSg={errors.errfName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"First Name"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="lastName"
                                id={"lastName"}
                                value={lastName}
                                errorMSg={errors.errLName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Last Name"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="adharLinkNo"
                                id={"adharLinkNo"}
                                value={adharLinkNo}
                                errorMSg={errors.errMobileno}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"tel"}
                                className={"form-control"}
                                placeholder={"Mobile"}
                                maxLength={"10"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="email"
                                id={"email"}
                                value={email}
                                errorMSg={errors.errEmail}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Email"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-12 text-center'>
                            <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
  )
}
