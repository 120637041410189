import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, Label, Input } from 'reactstrap'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Scale } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './emicalculator.scss'
import { numberText } from '../../../helper/method';


ChartJS.register(ArcElement, Tooltip, Legend);



const EMICalculator = () => {

    var arr = [1000000,10000000, 20000000, 30000000, 40000000, 50000000, 60000000, 70000000, 80000000, 90000000, 100000000,]
    var arr1 = [1, 5, 10, 15, 20, 25, 30]
 
    // var arr2 = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
    let emi, TotalamountofInterest;

    const emiRef = useRef(null);
    const yearRef = useRef(null);
    const rateRef = useRef(null);

    const [arr2, setArr2] = useState([])
    const [emiNtxt, setEmiNtxt] = useState(1000000)
    const [yearNtxt, setYearNtxt] = useState(5)
    const [rateNtxt, setRateNtxt] = useState(5)
    const [loanamount, setLoanamount] = useState(emiNtxt)
    const [loantenure, setLoantenure] = useState(yearNtxt)
    const [loaninterest, setLoaninterest] = useState(rateNtxt)
    const [mEmi, setMEMI] = useState(0)
    const [totalamountofInterest, setTotalamountofInterest] = useState(0)
    const [paybaleAmount, setPaybaleAmount] = useState()
    const [emitxt, setEmitxt] = useState(false)
    const [yeartxt, setYeartxt] = useState(false)
    const [ratetxt, setRatetxt] = useState(false)
  

    useEffect(() => {
        let a2=[]
        for(let i = 5; i <= 30; i++){
            a2.push(i)
        }
        setArr2(a2)
    },[])


    const CalculateEmi = (e) => {
        e.preventDefault();
        setEmitxt(false)
        setYeartxt(false)
        setRatetxt(false)
        let intr = loaninterest / 1200;
        emi = loantenure ? Math.round(loanamount * intr / (1 - (Math.pow(1 / (1 + intr), loantenure * 12)))) : 0;
        let totalAmt = (loantenure * 12) * emi;
        let TotalAmountofCredit = Math.round((emi / intr) * (1 - Math.pow((1 + intr), (-(loantenure * 12)))));
        TotalamountofInterest = Math.round(totalAmt - TotalAmountofCredit)
        setMEMI(emi)
        setTotalamountofInterest(TotalamountofInterest)
        let total = parseInt(TotalamountofInterest) + parseInt(loanamount)
        setPaybaleAmount(total)
    }

    const showTextBox = (value) => {
       
        if (value === "emi") {
            setEmitxt(!emitxt)
            setYeartxt(false)
            setRatetxt(false)
            // setEmiNtxt(0)
            // emiRef.current.focus();
        }
        if (value === "year") {
            setYeartxt(!yeartxt)
            setRatetxt(false)
            setEmitxt(false)
            // setYearNtxt(0)
            // yearRef.current.focus();
        }
        if (value === "rate") {
            setRatetxt(!ratetxt)
            setEmitxt(false)
            setYeartxt(false)
            // setRateNtxt(0)
            // rateRef.current.focus();
        }
    }

    const handeltxtChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "emiNtxt") {
            let check = value
            if (numberText(check)) {
                setEmiNtxt(check)
                Update(name, value)
            }
        }
        if (name === "yearNtxt") {
            let check = value
            if (numberText(check)) {
                setYearNtxt(check)
                Update(name, value)
            }
        }
        if (name === "rateNtxt") {
            let check = value
            if (numberText(check)) {
                setRateNtxt(check)
                Update(name, value)
            }
        }
    }

    const Update = (name, value) => {
        if(name === "emiNtxt"){
            setLoanamount(value)
        }
        if(name === "yearNtxt"){
            setLoantenure(value)
        }
        if(name === "rateNtxt"){
            setLoaninterest(value)
        }
    }


    const data = {
        // labels: ['total interest', 'principal amount'],
        datasets: [
            {
                label: '# of Votes',
                data: [totalamountofInterest, loanamount],
                backgroundColor: [
                    'rgba(237, 43, 144, 1)',
                    'rgba(70, 132, 197, 1)',
                ],
                borderColor: [
                    'rgba(237, 43, 144, 1)',
                    'rgba(70, 132, 197, 1)',
                ],
                borderWidth: 1,
                borderAlign: 'inner',
            },
        ],
    };




    const handleStateChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;


        setEmitxt(false)
        setYeartxt(false)
        setRatetxt(false)

        if (name === "loanamount") {
            setLoanamount(value)
            setEmiNtxt(value)
        }
        if (name === "loantenure") {
            setLoantenure(value)
            setYearNtxt(value)
        }
        if (name === "loaninterest") {
            setLoaninterest(value)
            setRateNtxt(value)
        }
    }


    return <div className='container'>
        <div className='emicalculator'>
            <h1>EMI calculator</h1>
            <div className='form-field'>
                <div className='emi_lbl'>
                    <Label>loan amount</Label>
                </div>
                <div className='emi_amt loan-amt'>
                    <Input
                        name="loanamount"
                        type="range"
                        min={1000000}
                        max={100000000}
                        defaultValue={loanamount}
                        value={loanamount}
                        step={1}
                        list="tickmarks"
                        className='inputField'
                        onChange={handleStateChange}
                    />
                </div>
                <div className='emi_range'>
                    <datalist id="tickmarks">
                        {
                            arr.map((item, i) => {
                                return <option key={i}  value={item} label={item}>10</option>
                            })
                        }
                    </datalist>
                </div>
                <div className='emi_field'>
                    {
                    !emitxt ? <lable onClick={() => showTextBox('emi')}>{loanamount.toLocaleString()} ₹</lable>
                        : <>
                            <input
                                type="text"
                                name="emiNtxt"
                                value={emiNtxt}
                                onChange={handeltxtChange}
                                ref={emiRef}
                            />
                            <span className='btn-ok' onClick={() => showTextBox("emi")}>ok</span>
                        </>
                    }
                </div>
            </div>
            <div className='form-field'>
                <div className='emi_lbl'>
                    <Label>loan tenure</Label>
                </div>
                <div className='emi_amt loan-tenure'>
                    <Input
                        name="loantenure"
                        type="range"
                        min={1}
                        max={30}
                        defaultValue={loantenure}
                        value={loantenure}
                        step={0.50}
                        list="tickmarks1"
                        className='inputField'
                        onChange={handleStateChange}
                    />
                </div>
                <div className='emi_range'>
                    <datalist id="tickmarks1">
                        {
                            arr1.map((item, i) => {
                                return <option  key={i} value={item} label={item}></option>
                            })
                        }
                    </datalist>
                </div>
                <div className='emi_field'>
                    {
                    !yeartxt ? <lable onClick={() => showTextBox('year')} >{loantenure} years</lable>
                        : <>
                            <input
                                type="text"
                                name="yearNtxt"
                                value={yearNtxt}
                                onChange={handeltxtChange}
                                ref={yearRef}
                            />
                            <span className='btn-ok' onClick={() => showTextBox("year")}>ok</span>
                        </>
                    }

                </div>
            </div>
            <div className='form-field'>
                <div className='emi_lbl'>
                    <Label>rate of interest</Label>
                </div>
                <div className='emi_amt roi'>
                    <Input
                        name="loaninterest"
                        type="range"
                        min={5}
                        max={30}
                        defaultValue={loaninterest}
                        value={loaninterest}
                        step={0.50}
                        list="tickmarks2"
                        className='inputField'
                        onChange={handleStateChange}
                    />
                </div>
                <div className='emi_range'>
                    <datalist id="tickmarks2">
                        {
                            arr2.map((item, i) => {
                                return <option  key={i} value={item} label={item}></option>
                            })
                        }
                    </datalist>
                </div>
                <div className='emi_field'>
                    {
                    !ratetxt ? <lable onClick={() => showTextBox('rate')} >{loaninterest} %</lable>
                        : <>
                            <input
                                type="text"
                                name="rateNtxt"
                                value={rateNtxt}
                                onChange={handeltxtChange}
                                ref={rateRef}
                            />
                            <span className='btn-ok' onClick={() => showTextBox("rate")}>ok</span>
                        </>
                    }
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <button onClick={CalculateEmi} className='btn btn-primary btn-sm'>Calculate</button>
                </div>
            </div>

            { totalamountofInterest ? <> 
                    <div className='loan_dtls'>
                        <h2>your monthly home loan EMI: <span className='emiAmt'>₹ {mEmi.toLocaleString()}</span></h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 text-center'>
                            <p>break-up of all total amount payable</p>
                        </div>
                        <div className='col-md-6'>
                            <p>your loan details as specified by you</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            {totalamountofInterest ?
                                <div className='PieChart'>
                                    <span className='innercircle'>
                                        <span>total amount payable</span>
                                        <span>₹ {paybaleAmount}</span>
                                    </span>
                                    <Pie data={data} />
                                    <ul>
                                        <li className='principle-amt'>principal amount <span>₹ {loanamount.toLocaleString()}</span></li>
                                        <li className='ttl-interest'>total interest <span>₹ {totalamountofInterest.toLocaleString()}</span></li>
                                    </ul>
                                </div>
                                : null}
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6'>
                                    <p>loan amount</p>
                                </div>
                                <div className='col-md-6 col-sm-6'><span>₹ {loanamount.toLocaleString()}</span></div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6'>
                                    <p>tenure</p>
                                </div>
                                <div className='col-md-6 col-sm-6'><span>{loantenure} years</span></div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6'>
                                    <p>interest rate</p>
                                </div>
                                <div className='col-md-6 col-sm-6'><span>{loaninterest} %</span></div>
                            </div>
                        </div>
                    </div> 
                </>
                : null
                }
        </div>
    </div>
}


export default EMICalculator;