import React from 'react'
import logoimage from '../../assets/images/rocket-loanz-logo-white.png';
import logoicon from '../../assets/images/icon-logo.png';
import { FaFacebookSquare,FaWhatsapp, FaLinkedin, FaInstagram } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer>
        <div className='container'>
            <div className='footer'>
                <div className='footer_logos'>
                <figure>
                <img src={logoimage} title='rocket loanz' />
                </figure>
                <span className='foot-img'>
                <img src={logoicon} title='rocket loanz' />
                </span>
            </div>
            <div className='footer_links'>
                <ul>
                    <li><a href='/howitworks'>how it works</a></li>                    
                    <li><a href='/about'>about</a></li>
                    <li><a href='/contactus'>contact us</a></li>
                    <li><a href='/EMICalculator'>EMI calculator</a></li>
                </ul>
                <div className='foot_links'>
                    
                    <div className='copyright'>
                        <span>Copyright © 2023 Rocket Loanz. All rights reserved.</span>
                        <span>Powered by <a href="https://www.sublimis.tech/" target="_blank">Sublimis | ePorium</a></span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </footer>
  )
}
