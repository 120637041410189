import { takeEvery, all } from 'redux-saga/effects';
import { EMAIL_FETCH } from '../redux/types/emailType';
import { EmailSaga } from './EmailSaga';

function* rootSaga() {
    yield all([
        takeEvery(EMAIL_FETCH, EmailSaga ),
    ]);
}

export default rootSaga;