import React,{ useState,useEffect } from 'react';
import { Progress, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { FaPlus,FaUpload }  from "react-icons/fa";
import { BiMinus, BiCalendarAlt }  from "react-icons/bi";

import { alphabatesWithSpace, alphaNumeric, numberText } from '../../../helper/method';
import TextInput from '../../molecules/input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './applicant.scss'
import { useStateWithCallback } from '../../lib/useStateWithCallback';
import AadharVerification from '../../shared/AadharVerification';


export default function Applicant() {
    const navigate = useNavigate();

    // var date = new Date();
    // var todayDate = moment(date, 'DD-MM-YYYY').format("DD-MM-YYYY");
    // let dDate = todayDate

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [panno, setPanno] = useState("")
    const [adharCard, setAdharCard] = useState("")
    const [dob, setDob] = useState(null)
    const [adharVerification, setAdharVerification] = useState(false)
    const [bankname, setBankname] = useState([
        { name : "", upload : null }
    ])
    const [selectedRadioId, setSelectedRadioId] = useStateWithCallback("1")
    const [radio, setRadio] = useState([
		{
			id: "1",
			label: "Salaried"
		},
		{
			id: "2",
			label: "Self Employed"
		},
	]);
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errPan: "",
        errMobileno: "",
        errAdharCard:"",
        // errBankname:"",
        // errUploade:"",
    })

    let hasError = false

    useEffect(() => {
        // setBankname()
    }, [])

    const addDynamicFields = () => {
        let data = { name : "", upload : null }
        setBankname([...bankname, data])
    }

    const removeDynamicField = (index) =>{
        let data = [...bankname];
        data.splice(index, 1)
        setBankname(data)
    }

    const handleFormChange = (index, event) => {
        let name = event.target.name;
        let value = event.target.value
        let data = [...bankname];
        if( name === "name" ){
            data[index][name] = value;
        }else if( name === "upload"){
            data[index][name] = event.target.files[0].name
        }
        setBankname(data);
     }

    const setSelectedDate = (data) => {
        // let getdata = moment(data, 'DD-MM-YYYY').format("DD-MM-YYYY")
        // let date =  getdata.toString()
        setDob(data);
    }

    const handleOnChangeRadioButton = (id, label) =>{
        setSelectedRadioId(id)
    }

    const handleAdharVerify = (e) =>{
        hasError = false;
        let allState = errors;
        e.preventDefault();

        if(!adharCard.length){
            allState["errAdharCard"] = " Adhar no is required"
            hasError = true
        }else if( adharCard.length < 12 ){
            allState["errAdharCard"] = "Invailid adhar no."
            hasError = true
        }else{
            allState["errAdharCard"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))

        if(!hasError){
            setAdharVerification(!adharVerification)
        }
    }

    const handleCloseModal = () => {
        setAdharVerification(!adharVerification)
    }

    console.log("Form data append", errors);    

    const handleStateChange = (name,value) => {

        console.log("handle OnBlure Change", name, "value", value)

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name,value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name,value)
            }
        }
        if(name === "panno"){
            let check =  value.toUpperCase();
            if(alphaNumeric(check)){
                setPanno(check)
                handleError(name, value)
            }
        }
        if(name === "adharCard"){
            let check = value
            if(numberText(check)){
                setAdharCard(check)
                handleError(name,value)
            }
        }
    }

    const handleError = (name, value) => {

         console.log("handle OnBlure Change", name, "value", value)
 
         let allState = errors;
 
         if(name === "firstName"){
             if(!value.length){
                 allState["errfName"] = "Enter your First name"
             }else if(value.length < 3){
                 allState["errfName"] = "Name length should be 3 charector"
             }else{
                 allState["errfName"] = ""
             }
         }
         if(name === "lastName"){
             if(!value.length){
                 allState["errLName"] = "Enter your Last name"
             }else if(value.length < 3){
                 allState["errLName"] = "Name length should be 3 charector"
             }else{
                 allState["errLName"] = ""
             }
         }
        if(name === "panno"){
            if(!value.length){
                allState["errPan"] = "Pan no is required"
            }else if(value.length < 10 ){
                allState["errPan"] = "Enter a valid Pan no"
            }else{
                allState["errPan"] = ""
            }
        }
         if(name === "adharCard"){
            if(!value.length){
                allState["errAdharCard"] = " Adhar no is required"
            }else if( value.length < 12 ){
                allState["errAdharCard"] = "Invailid adhar no."
            }else{
                allState["errAdharCard"] = ""
            }
        }
         setErrors(prevError => ({
             ...prevError,
             ...allState
         }))
     }


     const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        
        if(!panno.length){
            allState["errPan"] = "Pan no required"
            hasError = true
        }else if(panno.length < 10){
            allState["errPan"] = "Required 10 digits PAN"
            hasError = true
        }

        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }

        if(!adharCard.length){
            allState["errAdharCard"] = " Adhar no is required"
        }else if( adharCard.length < 12 ){
            allState["errAdharCard"] = "Invailid adhar no."
        }else{
            allState["errAdharCard"] = ""
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            let data = {
                firstName : firstName,
                lastName :  lastName,
                panno : panno,
                adharCard : adharCard,
                selectedRadioId : selectedRadioId,
                bankname : bankname
            }
            console.log("handeleFormSubmit", data)
            alert("test")
            navigate("/thankyouMessage")
        }
    }


  return (
    <section className='applicant'>
        <div className='container'>
            <div className='contact_sec'>
                <h2>let’s finish the journey.. </h2>
                <p>applicant details</p>
                <form className='form'>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="firstName"
                                id={"firstName"}
                                value={firstName}
                                errorMSg={errors.errfName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"First Name"}
                                autoComplete={"off"}
                                label={"First Name"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="lastName"
                                id={"lastName"}
                                value={lastName}
                                errorMSg={errors.errLName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Last Name"}
                                autoComplete={"off"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <div className='cust-cal'>
                                <DatePicker
                                selected={dob}
                                onChange={date => setSelectedDate(date)}
                                dateFormat = {"dd/MM/yyyy"}
                                placeholderText={'Date of Birth'}
                                className ="txtbox"
                                // showYearDropdown // year show and scrolldown alos
                                // scrollableYearDropdown
                            />
                            <BiCalendarAlt className='icon_cal' />
                            </div>
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="panno"
                                id={"panno"}
                                value={panno}
                                errorMSg={errors.errPan}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"PAN"}
                                maxLength={"10"}
                                autoComplete={"off"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12 verify'>
                            <TextInput
                                name="adharCard"
                                id={"adharCard"}
                                value={adharCard}
                                errorMSg={errors.errAdharCard}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Aadhaar Number"}
                                autoComplete={"off"}
                                maxLength={"12"}
                            />
                            <button className='adhar-verify' onClick={handleAdharVerify}>VERIFY</button>
                        </div>
                        <div className='col-md-6 col-sm-12 radio-btn'>
                            {
                                radio.map(({id, label}) => {
                                    return <FormGroup check key={id}>
                                        <Label check>
                                            <Input 
                                                type="radio" 
                                                name="radio" 
                                                id={id}
                                                //defaultChecked={ selectedRadioId === id }
                                                selected={id === selectedRadioId ? selectedRadioId : null }
                                                onClick={() => handleOnChangeRadioButton(id, label)}
                                            />{' '}
                                            {label}
                                        </Label>
                                    </FormGroup>
                                })
                            }
                        </div>
                    </div>
                    <hr></hr>
                        <span className='bank-span'>Upload Bank Statement (Last 12 months)</span>
                        {   bankname.map((input, index) => {
                            return <div className='row' key={index}>
                                    <div className='form-group col-md-6 col-sm-12'>
                                        <input
                                            name="name"
                                            id={"name"}
                                            value={input.name}
                                            // errorMSg={errors.errBankname}
                                            onChange={(event) => handleFormChange(index, event)}
                                            onBlur={handleError}
                                            type={"text"}
                                            className={"form-control"}
                                            placeholder={`Bank Name ${index+1}`}
                                            autoComplete={"off"}
                                        />
                                        <span>{input.name}</span>
                                    </div>
                                    <div className='form-group col-md-6 col-sm-12'>
                                        <div className='cust-uploadfile'>
                                            <FormGroup>
                                            <label>
                                            <FaUpload />
                                            Upload Bank Statement</label>
                                                <Input
                                                    type="file" 
                                                    name={"upload"}
                                                    className={"file-bank"}
                                                    onChange={(event) => handleFormChange(index, event)}
                                                    id="exampleCustomFileBrowser" 
                                                    accept='pdf, jpeg'
                                                    label="Upload Bank Statement" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <span className='minus' onClick={() => removeDynamicField(index)}>{bankname.length > 1 ? <BiMinus /> : null }</span>
                                </div>
                            })
                        }
                        <span className='plus' onClick={addDynamicFields}><FaPlus /></span>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <ul>
                                <li>Unlock your bank statement if it is password protected.</li>
                                <li>Click '+' to upload multiple bank statements to improve your eligibility.</li>
                                <li>File Format: <b>PDF, JPG</b>  Total File Size: <b>5 MB</b></li>
                            </ul>
                        </div>
                    </div>
                    <div className='row save-button'>  
                        <div className='form-group col-md-9 col-sm-12'>
                            <button type='submit' className='btn btn-block btn-sm submit' onClick={handeleFormSubmit}>Save</button>
                        </div>
                    </div>

                    <div className='row save-button'> 
                        <div className='form-group col-md-9 col-sm-12'>
                            <div className="progress mt-5">
                                <Progress value="60"></Progress>
                                <span>60%</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        { adharVerification ? <AadharVerification handleCloseModal = {handleCloseModal} /> : null }
    </section>
  )
}
