import React,{ useState } from 'react';
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import TextInput from '../../molecules/input'
import '../../../assets/Scss/register.css'
import { alphabatesWithSpace, alphaNumeric, isValidMobileNo, numberText } from '../../../helper/method';
import { useStateWithCallback } from '../../lib/useStateWithCallback';
import { FormGroup, Progress, Label, Input } from 'reactstrap';

const ProjectDetails = () => {
    const navigate = useNavigate();


    const [projectName, setProjectName] = useState("")
    const [txtProject, setTxtProject] = useState("")
    const [projectLocation, setProjectLocation] = useState("")
    const [configuration, setConfiguration] = useState("")
    const [agreement, setAgreement] = useState("")
    const [other, setSetOther] = useState(false)
    const [selectedRadioId, setSelectedRadioId] = useStateWithCallback("1")
    const [radio, setRadio] = useState([
		{
			id: "1",
			label: "Project selected"
		},
		{
			id: "2",
			label: "Project not selected"
		},
	]);
    const [errors, setErrors] = useState({
        errPName: "",
        errPLName: "",
        errConfig: "",
        errAgree: "",
        errTxtProject: "",
    })

    let hasError = false;


    const handleOnChangeRadioButton = (id, label) =>{
        setSelectedRadioId(id)
    }

    const handleStateChange = (name,value) => {

        if(name === "projectName"){
            setProjectName(value)
            handleError(name,value)
        }
        if(name === "projectLocation"){
            setProjectLocation(value)
            handleError(name,value)
        }
        
        if(name === "configuration"){
            setConfiguration(value)
            handleError(name,value)
        }
        if(name === "agreement"){
            setAgreement(value)
            handleError(name, value)
        }
    }

    const handleError = (name,value) => {
        
        console.log("handle OnBlure Change", name, "value", value)

        // let allState = errors;

        // if(name === "firstName"){
        //     if(!value.length){
        //         allState["errfName"] = "Enter your First name"
        //     }else if(value.length < 3){
        //         allState["errfName"] = "Name length should be 3 charector"
        //     }else{
        //         allState["errfName"] = ""
        //     }
        // }
        // if(name === "lastName"){
        //     if(!value.length){
        //         allState["errLName"] = "Enter your Last name"
        //     }else if(value.length < 3){
        //         allState["errLName"] = "Name length should be 3 charector"
        //     }else{
        //         allState["errLName"] = ""
        //     }
        // }
        // if(name === "panno"){
        //     if(!value.length){
        //         allState["errPan"] = "Pan no is required"
        //     }else if(value.length < 10 ){
        //         allState["errPan"] = "Enter a valid Pan no"
        //     }else{
        //         allState["errPan"] = ""
        //     }
        // }
        // if(name === "adharLinkNo"){
        //     if(!value.length){
        //         allState["errMobileno"] = "Enter Mobile number"
        //     }else if(value){
        //         if(!isValidMobileNo(value)){
        //             allState["errMobileno"] = "Invalid Mobile No."
        //         }else{
        //             allState["errMobileno"] = ""
        //         }
        //     }
        // }
        
        // setErrors(prevError => ({
        //     ...prevError,
        //     ...allState
        // }))
    }

    const checkVailidation = () =>{
    //     hasError = false;
    //     let allState = errors;
    //     let mobile = adharLinkNo.toString()
        
    //     if(!panno.length){
    //         allState["errPan"] = "Pan no required"
    //         hasError = true
    //     }else if(panno.length < 10){
    //         allState["errPan"] = "Enter a valid pan no"
    //         hasError = true
    //     }
    //     if(!firstName.length){
    //         allState["errfName"] = "Enter your first name"
    //         hasError = true
    //     }else if(firstName.length < 3){
    //         allState["errfName"] = "First Name should be 3 charector"
    //         hasError = true
    //     }
    //     if(!lastName.length){
    //         allState["errLName"] = "Enter your last name"
    //         hasError = true
    //     }else if(lastName.length < 3){
    //         allState["errLName"] = "Last Name should be 3 charector"
    //         hasError = true
    //     }
    //     if(!mobile.length){
    //         allState["errMobileno"] = "Enter Mobile number"
    //         hasError = true
    //     }else if( mobile.length ){
    //         if(!isValidMobileNo(adharLinkNo)){
    //             allState["errMobileno"] = "Invalid Mobile No."
    //         }else{
    //             allState["errMobileno"] = ""
    //         }
    //     }

    //     setErrors(prevError => ({
	// 		...prevError,
	// 		...allState
	// 	}))

    //     return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            alert("test")
            navigate("/financial/details")
        }
    }

    return (
        <div className='register project'>
            <div className='container'>
                <div className='captions'>
                    <h2>Project details</h2>
                </div>
                <div className='row'>
                    <div className='col-12 radio-btn'>
                        {
                            radio.map(({id, label}) => {
                                return<FormGroup check key={id}>
                                    <Label check>
                                        <Input 
                                            type="radio" 
                                            name="radio" 
                                            id="radio" 
                                            className={`${id === "2" ? "selector" : ""}`}
                                            defaultChecked={ selectedRadioId === id }
                                            selected={id === selectedRadioId ? selectedRadioId : null }
                                            onClick={() => handleOnChangeRadioButton(id, label)}
                                        />{' '}
                                        {label}
                                    </Label>
                                </FormGroup>
                            })
                        }
                    </div>
                </div>
                {   selectedRadioId === "1" ? 
                    <form className='form'>
                        <div className='row'>
                            <div className='form-group col-md-6 col-sm-12'>
                                <select className='texbox'>
                                    <option>Project Name</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <div className='form-group col-md-6 col-sm-12'>
                                <select className='texbox'>
                                    <option>Project Location</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                        </div>
                        { other ? 
                            <div className='row'>
                                <div className='form-group col-md-6 col-sm-12'>
                                    <TextInput
                                        name="txtProject"
                                        id={"txtProject"}
                                        value={txtProject}
                                        errorMSg={errors.errTxtProject}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        type={"text"}
                                        className={"form-control"}
                                        placeholder={"Project Name"}
                                        maxLength={"10"}
                                        autoComplete={"off"}
                                    />
                                </div>
                            </div> : null
                        }
                        <div className='row'>
                            <div className='form-group col-md-6 col-sm-12'>
                                <select className='texbox'>
                                    <option>Configuration</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <div className='form-group col-md-6 col-sm-12'>
                                <TextInput
                                    name="configuration"
                                    id={"configuration"}
                                    value={configuration}
                                    errorMSg={errors.errConfig}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    type={"text"}
                                    className={"form-control"}
                                    placeholder={"Agreement Value"}
                                    maxLength={"10"}
                                    autoComplete={"off"}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-8 col-sm-12 save-button next-button'>
                                <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>NEXT</button>
                            </div>
                        </div>
                    </form> : <form className='form'>
                        <div className='row'>
                            <div className='form-group col-md-8 col-sm-12 save-button next-button'>
                                <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>NEXT</button>
                            </div>
                        </div>
                    </form>
                }
                <div className='row'>
                    <div className='form-group col-md-9 col-sm-12 mx-auto'>
                        <div className="progress">
                            <Progress value="100"></Progress>
                            <span>100%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProjectDetails;