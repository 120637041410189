import React,{ useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Progress, FormGroup, Label, Input } from 'reactstrap';
import TextInput from '../../molecules/input';
import '../Applicant/applicant.scss'
import { useStateWithCallback } from '../../lib/useStateWithCallback';


export default function FinancialDetails() {
    const navigate = useNavigate();


    const [homeSalary, setHomeSalary] = useState("")
    const [fixedIncome, setFixedIncome] = useState("")
    const [monthlyEmi, setMonthlyEmi] = useState("")
    const [check, setCheck] = useState("")
    const [selectedRadioId, setSelectedRadioId] = useStateWithCallback("1")
    const [radio, setRadio] = useState([
		{
			id: "1",
			label: "Salaried"
		},
		{
			id: "2",
			label: "Self Employed"
		},
	]);
    const [errors, setErrors] = useState({
        errHomeSalary: "",
        errFixedIncome: "",
        errMonthlyEmi: "",
    })

    let hasError = false

    const handleOnChangeRadioButton = (id, label) =>{
        setSelectedRadioId(id)
    }

    const handleStateChange = (name,value) => {

        if(name === "homeSalary"){
            setHomeSalary(value)
            handleError(name,value)
        }
        if(name === "fixedIncome"){
            setFixedIncome(value)
            handleError(name,value)
        }
        if(name === "monthlyEmi"){
            setMonthlyEmi(value)
            handleError(name, value)
        }
    }

    const handleError = (name, value) => {

        console.log("handle OnBlure Change", name, "value", value)

        let allState = errors;

        if(name === "homeSalary"){
            if(!value.length){
                allState["errHomeSalary"] = "Home salary required"
            }else{
                allState["errHomeSalary"] = ""
            }
        }
        if(name === "fixedIncome"){
            if(!value.length){
                allState["errFixedIncome"] = "Fixed income required"
            }else{
                allState["errFixedIncome"] = ""
            }
        }
        if(name === "monthlyEmi"){
            if(!value.length){
                allState["errMonthlyEmi"] = "Monthly emi required"
            }else{
                allState["errMonthlyEmi"] = ""
            }
        }
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
     }


     const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        
        if(!homeSalary.length){
            allState["errHomeSalary"] = "Home salary required"
            hasError = true
        }

        if(!fixedIncome.length){
            allState["errFixedIncome"] = "Fixed income required"
            hasError = true
        }
        
        if(!monthlyEmi.length){
            allState["errMonthlyEmi"] = "Monthly emi required"
            hasError = true
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            let data = {
                homeSalary : homeSalary,
                fixedIncome :  fixedIncome,
                monthlyEmi : monthlyEmi,
            }
            console.log("handeleFormSubmit", data)
            alert("test")
            navigate("/thankyouMessage")
        }
    }


  return (
    <section className='applicant project'>
        <div className='container'>
            <div className='contact_sec'>
                <h2>Financial details</h2>
                <form className='form'>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="homeSalary"
                                id={"homeSalary"}
                                value={homeSalary}
                                errorMSg={errors.errHomeSalary}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Monthly take home salary"}
                                autoComplete={"off"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="fixedIncome"
                                id={"fixedIncome"}
                                value={fixedIncome}
                                errorMSg={errors.errFixedIncome}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Any other fixed monthly income"}
                                autoComplete={"off"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="monthlyEmi"
                                id={"monthlyEmi"}
                                value={monthlyEmi}
                                errorMSg={errors.errMonthlyEmi}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Total Monthly EMIs"}
                                autoComplete={"off"}
                                maxLength={"16"}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12 radio-btn'>
                            {
                                radio.map(({id, label}) => {
                                    return<FormGroup check key={id}>
                                        <Label check>
                                            <Input 
                                                type="radio" 
                                                name="radio" 
                                                id={id}
                                                defaultChecked={ selectedRadioId === id }
                                                selected={id === selectedRadioId ? selectedRadioId : null }
                                                onClick={() => handleOnChangeRadioButton(id, label)}
                                            />{' '}
                                            {label}
                                        </Label>
                                    </FormGroup>
                                })
                            }
                        </div>
                    </div>

                    <div className='row'>
                        <div className="form-check form-check-inline">
                            <FormGroup check inline>
                                <Label check>
                                    <Input type="checkbox" /> I hereby appoint Rocket Loanz as my authorised representative to receive my credit information from Bureau
                                </Label>
                            </FormGroup>
                        </div>
                    </div>
                   
                    <div className='row save-button'>  
                        <div className='form-group col-md-8 col-sm-12'>
                            <button type='submit' className='btn btn-block btn-sm submit' onClick={handeleFormSubmit}>Submit</button>
                        </div>
                    </div>

                    <div className='row save-button'> 
                        <div className='form-group col-md-8 col-sm-12'>
                            <div className="progress">
                                <Progress value="25"></Progress>
                                <span>25%</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
  )
}
