import React,{useState} from 'react';
import Slider from "../../atom/slider/newSlider";
import { Progress } from 'reactstrap';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../../../assets/Scss/project_slider.css";


import PiramalMahalaxmi from '../../../assets/images/piramal-mahalaxmi.jpg';
import SunteckOneWorld from '../../../assets/images/sunteck-one-world.jpg';
import RustomjeeSeasons from '../../../assets/images/rustomjee-seasons.jpg';
import LodhaWorldCrest from '../../../assets/images/lodha-world-crest.jpg';


const data = [
    {id : 1, figcaption: "Naigaon, Mumbai", h5 : "Sunteck One World", src : SunteckOneWorld, alt : "Sunteck One World", title : "Sunteck One World",}, 
    {id : 2, figcaption: "Bandra (E), Mumbai", h5 : "Rustomjee Seasons", src : RustomjeeSeasons, alt : "Rustomjee Seasons", title : "Rustomjee Seasons",},
    {id : 3, figcaption: "Lower Parel, Mumbai", h5 : "Lodha World Crest", src : LodhaWorldCrest, alt : "Lodha World Crest", title : "Lodha World Crest",},
    {id : 4, figcaption: "Naigaon, Mumbai", h5 : "Sunteck One World", src : SunteckOneWorld, alt : "Sunteck One World", title : "Sunteck One World"},
    {id : 5, figcaption: "Bandra (E), Mumbai", h5 : "Rustomjee Seasons", src : RustomjeeSeasons, alt : "Rustomjee Seasons", title : "Rustomjee Seasons",},
    {id : 6, figcaption: "Lower Parel, Mumbai", h5 : "Lodha World Crest", src : LodhaWorldCrest, alt : "Lodha World Crest", title : "Lodha World Crest",},
]

export default function Projects() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        
      };
    //   const [isShown, setIsShown] = useState(false);
  return (
    <section className='inner-pg'>
        <div className='container'>
            <h3>select your project</h3>
            <Slider slidesToShow={4} slidesToScrol={1} dots={true} >
                
                    {
                        data.length && data.map((item,index) =>{
                        return <div className='row'>
                            <div className='col-md-4 col-sm-12'>
                                <div className='cards' key={index}>
                                        <figure><img src={item.src} alt={item.alt} title={item.title} />
                                            <figcaption>{item.figcaption}</figcaption>
                                        </figure>
                                        <figcaption>
                                            <h5>{item.h5}</h5>
                                            <button className='btn btn-block'>Select</button>
                                        </figcaption>
                                        <div className='hover_dtls'>
                                            <ul>
                                                <li>Select configuration</li>
                                                <li><a href='#'>1 BHK</a></li>
                                                <li><a href='#'>1.5 BHK</a></li>
                                                <li><a href='#'>2 BHK</a></li>
                                                <li><a href='#'>3 BHK</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
            </Slider>

            <div className='row'>
                <div className='form-group col-md-9 col-sm-12 mx-auto'>
                    <div className="progress">
                        <Progress value="30"></Progress>
                        <span>30%</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
