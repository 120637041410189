import Api from '.';
import { endpoints } from './endpoints';

// const token = localStorage.getItem("token")

export default class emailApi extends Api {

//     Auth(data) {
//         let url = this.buildUrl(endpoints.AuthUrl.auth)
//         return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
//     }

//     Posts(data){
//         let url = this.buildUrl(endpoints.Posts.posts)
//         return this.fetch(url, "GET", null, data).then(response => response)
//     }

//     Experiences(data) {
//         let url = this.buildUrl(endpoints.HomeUrl.experiences)
//         return this.fetch(url, "GET", null, data).then(response => response)
//     }

    UserRequest(data) {
        let url = this.buildUrl(endpoints.Emailpush.userRequest)
        return this.fetchNormal(url, "POST", JSON.stringify(data), null).then(response => response)
    }


}