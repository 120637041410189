//let url = "https://" + window.location.host + "/moslcms/cms/";
// let url = process.env.CMS_BASE_URL;
let baseUrl = process.env.REACT_APP_BASE_URL;

// let baseAPI = 'http://dxtechsol.com:8080/api/v1/';
// console.log(url, '<--urlEndpoint-->', baseAPI)

export const endpoints = {

    baseUrl,

    Emailpush: {
        userRequest: "/userRequest",
    },
    HomeUrl: {
        homePage: "cmsPage/home-page",
    }
}