import React,{ useEffect, useState} from 'react';
import './style.scss';
import cibilGraph from '../../../assets/images/cibil-Graph.png';
import TextInput from '../../molecules/input';
import { alphabatesWithSpace, alphaNumeric, isValidMobileNo, numberText } from '../../../helper/method';

export default function CreditscoreLP() {

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [panNo, setPanNo] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errPanNo: "",
        errMobileno: "",
    })

    let hasError = false;

    const handleStateChange = (name, value) => {
        console.log(name ,"and" , value)

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name, value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name, value)
            }
        }
        if(name === "panNo"){
            let check =  value.toUpperCase();
            if(alphaNumeric(check)){
                setPanNo(check)
                handleError(name, value)
            }
        }
        if(name === "mobileno"){
            let check = value
            if(numberText(check)){
                setMobileno(check)
                handleError(name, value)
            }
        }
    }

    const handleError = (name, value) => {

         let allState = errors;
 
         if(name === "firstName"){
             if(!value.length){
                 allState["errfName"] = "Enter your First name"
             }else if(value.length < 3){
                 allState["errfName"] = "Name length should be 3 charector"
             }else{
                 allState["errfName"] = ""
             }
         }
         if(name === "lastName"){
             if(!value.length){
                 allState["errLName"] = "Enter your Last name"
             }else if(value.length < 3){
                 allState["errLName"] = "Name length should be 3 charector"
             }else{
                 allState["errLName"] = ""
             }
         }
         if(name === "mobileno"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
         if(name === "panNo"){
            if(!value.length){
                allState["errPanNo"] = "Pan no required."
            }else if( value.length ){
                if(value.length < 8 ){
                    allState["errPanNo"] = "Pan no should be 8 charector"
                }else{
                    allState["errPanNo"] = ""
                }
            }
        }
         setErrors(prevError => ({
             ...prevError,
             ...allState
         }))
     }


    const checkVailidation = () =>{
        hasError = false;

        let allState = errors;
        let mobile = mobileno.toString()
        
        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(mobileno)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }
        if(!panNo.length){
            allState["errPanNo"] = "Pan no required."
            hasError = true
        }else{
            allState["errPanNo"] = ""
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            alert("test")
        }
    }

    return (
        <div className='creditscore-main'>
            <div className='container'>
                <div className='credit-inner'>
                    <div className='credit-title'>
                        <h2>credit score</h2>
                        <h3>get your <span className="free">free</span> score now</h3>
                    </div>
                    <div className='credit-img'>
                        <figure>
                            <img src={cibilGraph}></img>
                        </figure>
                        <span>checking your credit score will not impact your score</span>
                        <form>
                            <div className='row'>
                                <div className='form-group col-md-6 col-sm-12'>
                                    <TextInput
                                        name="firstName"
                                        id={"firstName"}
                                        value={firstName}
                                        errorMSg={errors.errfName}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        type={"text"}
                                        className={"form-control"}
                                        placeholder={"First Name"}
                                    />
                                </div>
                                <div className='form-group col-md-6 col-sm-12'>
                                    <TextInput
                                        name="lastName"
                                        id={"lastName"}
                                        value={lastName}
                                        errorMSg={errors.errLName}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        type={"text"}
                                        className={"form-control"}
                                        placeholder={"Last Name"}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='form-group col-md-6 col-sm-12'>
                                    <TextInput
                                        name="mobileno"
                                        id={"mobileno"}
                                        value={mobileno}
                                        errorMSg={errors.errMobileno}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        type={"tel"}
                                        className={"form-control"}
                                        placeholder={"Mobile"}
                                        maxLength={"10"}
                                    />
                                </div>
                                <div className='form-group col-md-6 col-sm-12'>
                                    <TextInput
                                        name="panNo"
                                        id={"panNo"}
                                        value={panNo}
                                        errorMSg={errors.errPanNo}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        type={"text"}
                                        className={"form-control"}
                                        placeholder={"Pan no"}
                                        maxLength={"8"}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='form-group col-12'>
                                    <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

