import React,{ useState } from 'react';
import { Progress, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { alphabatesWithSpace, isValidMobileNo, numberText } from '../../../helper/method';
import TextInput from '../../molecules/input';
import { BiCalendarAlt }  from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './applicant.scss'
import { useStateWithCallback } from '../../lib/useStateWithCallback';
import AadharVerification from '../../shared/AadharVerification';
import MobileVerification from '../../shared/MobileVerification';


export default function CoApplicant() {
    const navigate = useNavigate();

    // var date = new Date();
    // var todayDate = moment(date, 'DD-MM-YYYY').format("DD-MM-YYYY");
    // let dDate = todayDate

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [panno, setPanno] = useState("")
    const [adharCard, setAdharCard] = useState("")
    const [adharVerification, setAdharVerification] = useState(false)
    const [mobileVerification, setMobileVerification] = useState(false)
    const [dob, setDob] = useState(null)
    const [selectedRadioId, setSelectedRadioId] = useStateWithCallback("1")
    const [selectedRelation, setSelectedRelation] = useStateWithCallback("1")
    const [radio, setRadio] = useState([
		{
			id: "1",
			label: "Salaried"
		},
		{
			id: "2",
			label: "Self Employed"
		},
	]);

    const [relation, setRelation] = useState([
		{ id: "1",	label: "Spouse" },
		{ id: "2",	label: "Father" },
		{ id: "3",	label: "Mother" },
		{ id: "4",	label: "Son" },
		{ id: "5",	label: "Daughter" },
	]);
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errPan: "",
        errMobileno: "",
        errAdharCard:"",
        errBankname:"",
        errDob:"",
    })

    let hasError = false

    const setSelectedDate = (data) => {
        // let getdata = moment(data, 'DD-MM-YYYY').format("DD-MM-YYYY")
        // let date =  getdata.toString()
        setDob(data);
    }

    const handleOnChangeRadioButton = (id, label) =>{
        setSelectedRadioId(label)
    }
    const handleOnChangeRelationship = (id, label) =>{
        setSelectedRelation(label)
    }


    const handleAdharVerify = (e) =>{
        hasError = false;
        let allState = errors;
        e.preventDefault();

        if(!adharCard.length){
            allState["errAdharCard"] = " Adhar no is required"
            hasError = true
        }else if( adharCard.length < 16 ){
            allState["errAdharCard"] = "Invailid adhar no."
            hasError = true
        }else{
            allState["errAdharCard"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))

        if(!hasError){
            setAdharVerification(!adharVerification)
        }
    }

    const handleCloseModal = () => {
        setAdharVerification(!adharVerification)
    }

    const handleMobileVerify = (e) =>{
        hasError = false;
        let allState = errors;
        e.preventDefault();

        if(!mobileno.length){
            allState["errMobileno"] = "Mobile number required"
            hasError = true
            }else if( mobileno.length < 8 ){
                allState["errMobileno"] = "Please enter at least 8 characters."
            }else{
            allState["errMobileno"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))

        if(!hasError){
            setMobileVerification(!mobileVerification)
        }
    }

    const handleMobCloseModal = () => {
        setMobileVerification(!mobileVerification)
    }

    const handleStateChange = (name,value) => {

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name,value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name,value)
            }
        }
        if(name === "panno"){
            setPanno(value)
            handleError(name,value)
        }
        if(name === "adharCard"){
            let check = value
            if(numberText(check)){
                setAdharCard(check)
                handleError(name,value)
            }
        }
        if(name === "mobileno"){
            let check = value
            if(numberText(check)){
                setMobileno(check)
                handleError(name,value)
            }
        }
    }

    const handleError = (name, value) => {

         console.log("handle OnBlure Change", name, "value", value)
 
         let allState = errors;
 
         if(name === "firstName"){
             if(!value.length){
                 allState["errfName"] = "Enter your First name"
             }else if(value.length < 3){
                 allState["errfName"] = "Name length should be 3 charector"
             }else{
                 allState["errfName"] = ""
             }
         }
         if(name === "lastName"){
             if(!value.length){
                 allState["errLName"] = "Enter your Last name"
             }else if(value.length < 3){
                 allState["errLName"] = "Name length should be 3 charector"
             }else{
                 allState["errLName"] = ""
             }
         }

        if(name === "mobileno"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
        
        if(name === "adharCard"){
            if(!value.length){
                allState["errAdharCard"] = " Adhar no is required"
            }else if( value.length < 16 ){
                allState["errAdharCard"] = "Invailid adhar no."
            }else{
                allState["errAdharCard"] = ""
            }
        }
            setErrors(prevError => ({
                ...prevError,
                ...allState
            }))
        }


     const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        
        if(!panno.length){
            allState["errPan"] = "Pan no required"
            hasError = true
        }else if(!panno.length < 10){
            allState["errEmail"] = "Enter a valid pan no"
            hasError = true
        }

        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobileno.length){
            allState["errMobileno"] = "Mobile number required"
            hasError = true
            }else if( mobileno.length < 8 ){
                allState["errMobileno"] = "Please enter at least 8 characters."
            }else{
            allState["errMobileno"] = ""
        }

        if(!adharCard.length){
            allState["errAdharCard"] = " Adhar no is required"
        }else if( adharCard.length < 16 ){
            allState["errAdharCard"] = "Invailid adhar no."
        }else{
            allState["errAdharCard"] = ""
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            alert("test")
            navigate("/thankyouMessage")
        }
    }

 console.log("Form data selectedRadioId", selectedRadioId, "selectedRelation", selectedRelation);    

  return (
    <section className='applicant'>
        <div className='container'>
            <div className='contact_sec co-applicant'>
                <h2>let’s finish the journey.. </h2>
                <p>co-applicant details</p>
                <form className='form'>
                    <div className='row'>
                        <span>Relationship with Applicant</span>
                        <div className='col-md-12 col-sm-6 radio-btn'>
                            {
                                relation.map(({id, label}) => {
                                    return<FormGroup check key={id}>
                                        <Label check>
                                            <Input 
                                                type="radio" 
                                                name="relation" 
                                                id={id}  
                                                //defaultChecked={ selectedRelation === id }
                                                selected={id === selectedRelation ? selectedRelation : null }
                                            />{' '}
                                            {label}
                                        </Label>
                                    </FormGroup>
                                })
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="firstName"
                                id={"firstName"}
                                value={firstName}
                                errorMSg={errors.errfName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"First Name (as per Aadhaar)"}
                                autoComplete={"off"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="lastName"
                                id={"lastName"}
                                value={lastName}
                                errorMSg={errors.errLName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Last Name (as per Aadhaar)"}
                                autoComplete={"off"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                        <div className='cust-cal'>
                            <DatePicker
                                selected={dob}
                                onChange={date => setSelectedDate(date)}
                                dateFormat = {"dd/MM/yyyy"}
                                placeholderText={'Date of Birth'}
                                className ="txtbox"
                                // showYearDropdown // year show and scrolldown alos
                                // scrollableYearDropdown
                            />
                            <BiCalendarAlt className='icon_cal' />
                            </div>
                            {/* <span className='text-danger'>{errors.errDob}</span> */}
                        </div>
                        <div className='form-group col-md-6 col-sm-12 verify'>
                            <TextInput
                                name="mobileno"
                                id={"mobileno"}
                                value={mobileno}
                                errorMSg={errors.errMobileno}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Aadhaar-Linked Mobile"}
                                autoComplete={"off"}
                                maxLength={"10"}
                            />
                            <button className='adhar-verify' onClick={handleMobileVerify}>Verify</button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12 verify'>
                            <TextInput
                                name="adharCard"
                                id={"adharCard"}
                                value={adharCard}
                                errorMSg={errors.errAdharCard}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Aadhar Number"}
                                autoComplete={"off"}
                                maxLength={"16"}
                            />
                            <button className='adhar-verify' onClick={handleAdharVerify} >VERIFY</button>
                        </div>
                        <div className='col-md-6 col-sm-12 radio-btn'>
                            {
                                radio.map(({id, label}) => {
                                    return<FormGroup check key={id}>
                                        <Label check>
                                            <Input 
                                                type="radio" 
                                                name="radio" 
                                                id={id} 
                                                //defaultChecked={ selectedRadioId === id }
                                                selected={id === selectedRadioId ? selectedRadioId : null }
                                                onClick={() => handleOnChangeRadioButton(id, label)}
                                            />{' '}
                                            {label}
                                        </Label>
                                    </FormGroup>
                                })
                            }
                        </div>
                    </div>
                    <div className='row save-button'>  
                        <div className='form-group col-md-8 col-sm-12'>
                            <button type='submit' className='btn btn-block btn-sm submit' onClick={handeleFormSubmit}>Save & Submit</button>
                        </div>
                    </div>

                    <div className='row save-button'> 
                        <div className='form-group col-md-9 col-sm-12'>
                            <div className="progress">
                                <Progress value="80"></Progress>
                                <span>80%</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        { adharVerification ? <AadharVerification handleCloseModal = {handleCloseModal} /> : null }
        { mobileVerification ? <MobileVerification handleCloseModal = {handleMobCloseModal} /> : null }
    </section>
  )
}
