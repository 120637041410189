import React from 'react'

export default function ProvisionalSanctionLetter() {
  return (
    <div className='inner-pg provision_letter'>
    <section className='whiteBg'>
        <div className='container'>
        <h3>provisional sanction letter </h3>
        <div className='d-flex justify-content-between mb-5 mt-5'>
            <span>Application Number: 512</span>
            <span>28 Jun, 2022</span>
        </div>
        <h6><b>Dear Kaushal Ranpura,</b></h6>
        <p className='mt-5'>We are pleased to inform you that your downpayment home loan has been approved as per terms and conditions mentioned below:</p>
        <h4>loan details</h4>
        <div className='table-responsive table-bordered'>
            <table className='table tbl-style3'>
                <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Location</th>
                        <th>Configuration</th>
                        <th>Agreement Value</th>
                        <th>Loan Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hesperian Reality</td>
                        <td>Parel, Mumbai</td>
                        <td>2 BHK</td>
                        <td>₹ 1,32,00,000</td>
                        <td>₹ 1,20,00,000</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className='mt-5 table-responsive'>
        <table className='table tbl-style3'>
        <thead>
            <tr>
                <th></th>
                <th>HDFC Bank</th>
                <th>LIC HFL</th>
                <th>ICICI Bank</th>
                <th>Axis Bank</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className='align-left'>Eligibility</td>
                <td>₹ 40,47,000</td>
                <td>₹ 48,12,000</td>
                <td>₹ 50,38,000</td>
                <td>₹ 50,38,000</td>
            </tr>
            <tr>
                <td>Loan Tenure (in INR)</td>
                <td>336</td>
                <td>300</td>
                <td>336</td>
                <td>336</td>
            </tr>
            <tr>
                <td>EMI (in INR)</td>
                <td>₹ 26,446</td>
                <td>₹ 32,247</td>
                <td>₹ 33,249</td>
                <td>₹ 33,249</td>
            </tr>
            <tr>
                <td>Rate</td>
                <td>6.6%</td>
                <td>6.75%</td>
                <td>6.7%</td>
                <td>6.7%</td>
            </tr>
        </tbody>
    </table>
        </div>
        <p>The applicants are required to sign this sanction letter towards the acceptance of all its terms and conditions. </p>
        <p><b>Terms and conditions</b></p>
        <ol>
            <li>All property onus to be on the loan structure.</li>
            <li>This is a provisional sanction, based on the initial assessment. Bank/FI will share the final sanction post payment of initial processing fees.</li>
            <li>This letter is valid for 15 days from the date of issue.</li>
            <li>Disbursal pf the proposed loan is subject to legal and technical clearance of property being offered as collateral (as per the norms of Bank/FI).</li>
        </ol>
    </div>
    </section>
</div>
  )
}
