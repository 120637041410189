import React,{ useState } from 'react';
import { FaFacebookSquare, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { alphabatesWithSpace, fetchWithWait, isValidMobileNo, numberText, isValidEmail } from '../../../helper/method';
import TextInput from '../../molecules/input';
import { useDispatch } from 'react-redux';
import { sendEamilAction } from '../../../redux/actions/emailAction';
import { useNavigate } from 'react-router-dom';
import PageLaoder from "../../atom/loader/pageLaoder"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ContactUs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [querryMessage, setquerryMessage] = useState("")
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errEmail: "",
        errMobileno: "",
    })

    let hasError = false

    const handleStateChange = (name,value) => {

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name,value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name,value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobileno"){
            let check = value
            if(numberText(check)){
                setMobileno(check)
                handleError(name,value)
            }
        }
        if(name === "querryMessage"){
            setquerryMessage(value)
        }
    }

    const handleError = (name, value) => {
 
         let allState = errors;
 
         if(name === "firstName"){
             if(!value.length){
                 allState["errfName"] = "Enter your First name"
             }else if(value.length < 3){
                 allState["errfName"] = "Name length should be 3 charector"
             }else{
                 allState["errfName"] = ""
             }
         }
         if(name === "lastName"){
             if(!value.length){
                 allState["errLName"] = "Enter your Last name"
             }else if(value.length < 3){
                 allState["errLName"] = "Name length should be 3 charector"
             }else{
                 allState["errLName"] = ""
             }
         }
         if(name === "email"){
             if(!value.length){
                 allState["errEmail"] = "Email address is required"
             }else if(!isValidEmail(value)){
                 allState["errEmail"] = "Enter a valid email address"
             }else{
                 allState["errEmail"] = ""
             }
         }
         if(name === "mobileno"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
         
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
     }


     const checkVailidation = () =>{
        hasError = false;
        let allState = errors;

        let mobile = mobileno.toString()
        
        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }
        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(mobileno)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        let data = {
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "mobileno": mobileno,
            "textmessage": querryMessage
        }

        if(!checkVailidation()){
            setLoading(true)
            fetchWithWait({ dispatch, action: sendEamilAction(data) }).then((res) => {
            if(res.status === 200 ){
                setLoading(false)
                navigate("/thankyouMessage", {state: data})
            }else{
                toast.error(res.message);
            }
            }).catch((e) => {
                console.log("error",e);
            })
        }
    }


  return (
    <section className='inner-pg'>
        <div className='container'>
            <div className='contact_sec'>
                <h1>Contact us</h1>
                <form className='form'>
                        <div className='row'>
                            <div className='form-group col-md-6 col-sm-12'>
                                <TextInput
                                    name="firstName"
                                    id={"firstName"}
                                    value={firstName}
                                    errorMSg={errors.errfName}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    type={"text"}
                                    className={"form-control"}
                                    placeholder={"First Name"}
                                    autoComplete={"off"}
                                />
                            </div>
                            <div className='form-group col-md-6 col-sm-12'>
                                <TextInput
                                    name="lastName"
                                    id={"lastName"}
                                    value={lastName}
                                    errorMSg={errors.errLName}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    type={"text"}
                                    className={"form-control"}
                                    placeholder={"Last Name"}
                                    autoComplete={"off"}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-6 col-sm-12'>
                                <TextInput
                                    name="mobileno"
                                    id={"mobileno"}
                                    value={mobileno}
                                    errorMSg={errors.errMobileno}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    type={"tel"}
                                    className={"form-control"}
                                    placeholder={"Enter Mobile number*"}
                                    maxLength={"10"}
                                />
                            </div>
                            <div className='form-group col-md-6 col-sm-12'>
                                <TextInput
                                    name="email"
                                    id={"email"}
                                    value={email}
                                    errorMSg={errors.errEmail}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    type={"text"}
                                    className={"form-control"}
                                    placeholder={"Email"}
                                    autoComplete={"off"}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-12'>
                            <textarea 
                                className='form-contrrol'
                                placeholder='Type here your query'
                                onChange={(e) => handleStateChange("querryMessage", e.target.value)}
                                    name="querryMessage"
                                    value={querryMessage}
                                    onBlur={handleError}
                            ></textarea>  
                            <span className="text-danger">{errors.errquerrymessage}</span>  
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-12 text-center'>
                                <button type='submit' className='btn btn-block btn-sm' onClick={handeleFormSubmit}>Submit</button>
                            </div>
                        </div>
                </form>
                <h2>Corporate Office</h2>
                <address>A 905, O2 Commercial, Asha Nagar, Mulund (W), Mumbai 400080</address>
                <ul className='contactDetails'>
                    <li><a href='tel:912225673377'>+91 22 25673377</a></li>
                    <li><a href='mailto:prateek@rocketloanz.com '>prateek@rocketloanz.com</a></li>
                </ul>
                {/* <ul className='socialMedia'>
                    <li>
                        <a href='#'><FaFacebookSquare /></a>
                    </li>
                    <li>
                        <a href='#'><FaLinkedin /></a>
                    </li>
                    <li>
                        <a href='#'><FaInstagram /></a>
                    </li>
                </ul> */}
                <div className=''>
                
                </div>
            </div>
        </div>
        <ToastContainer />
        {loading &&
            <PageLaoder />
        }
    </section>
    
  )
}
