import React from 'react'
import DownpaymentBg from '../../../assets/images/downpayment-bg.jpg';
import IcnInstant from '../../../assets/images/icon-instant.png';
import IcnEMI from '../../../assets/images/icon-emi.png';
import IcnMonths from '../../../assets/images/icon-months.png';
import IcnHouse from '../../../assets/images/icon-house.png';
import IcnKYC from '../../../assets/images/icon-kyc.png';
import BankStatement from '../../../assets/images/icon-bankstatement.png';
import IcnInstantApproval from '../../../assets/images/icon-instant-white.png';

import { Link } from "react-router-dom";
import { icon } from '@fortawesome/fontawesome-svg-core';

const feature = [
  {id:'1',caption:'instant',icon:IcnInstant,lable:'loan approval'},
  {id:'2',caption:'interest-free',icon:IcnEMI,lable:'EMIs'},
  {id:'3',caption:'monthly',icon:IcnMonths,lable:'installments'},
  {id:'4',caption:'no lien',icon:IcnHouse,lable:'on your house'}
]
const steps = [
  {id:'1',steps:'step 01',icon:IcnKYC,lable:'verify your KYC', liClass:'stepOne'},
  {id:'2',steps:'step 02',icon:BankStatement,lable:'upload bank statement',liClass:'stepTwo'},
  {id:'3',steps:'step 03',icon:IcnInstantApproval,lable:'get instant approval',liClass:'stepThree'}
]


export default function HowItWorks() {
  return (
    <div className='downPayment-sec'>
      <figure>
        <img src={DownpaymentBg} />
      </figure>
      <section className='finace_sec'>
        
        <div className='container'>
          <h2>how it works</h2>
          <div className='row row-md'>
            <div className='col-sm-6'>
              <h4>home buyer's challenge</h4>
              <ul>
                <li>Banks provide home loan up to 90% of agreement value. The remaining funds, which is called own contribution, have to be arranged by the home buyer.</li>
                <li>Arranging the own contribution has always remained a challenge for the home buyers.</li>
              </ul>
              <h4>solution</h4>
              <ul>
                <li>We fund a part of your own contribution at 0% rate of interest. You have to repay just the principal amount in 12 monthly installments.</li>
                <li>This product is called Downpayment Finance.</li>
              </ul>
            </div>
            <div className='col-sm-6'>
              <div className='agreement-value'>

                  
                  <div className='agreement-value-dtls'>
                    <span>
                      <label>₹ 30 Lacs</label>
                      agreement value
                    </span>
                  </div>
                  <ul className='agreement-bifurcation'>
                    <li className='purple'>
                      <span><label>₹ 27 Lacs home loan</label></span>
                    </li>
                    <li className='orange'>
                      <span><label>₹ 1.5 Lacs you pay</label></span>
                    </li>
                    <li className='blue'>
                      <span><label><small>upto</small>₹ 1.5 Lacks we pay</label></span>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <Link to="/DownPayment" className='btn btn-primary'>Apply Now</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

