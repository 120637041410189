import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import '../../assets/Scss/register.css'

export default function ThankyouMessage() {
    const navigate = useNavigate();
    const location = useLocation();

    const backToHome = () => {
        navigate("/")
    }

    const { firstname,lastname } = location.state

  return (
    <section>
        <div className='container'>
            <div className='innerSec'>
                <h5>{firstname} {lastname}</h5>
                <h4>thank you for applying with us</h4>
                <p>Our representative will get back to you shortly.</p>
                <div className='row'>
                    <div className='form-group col-12 text-center'>
                        <button onClick={backToHome} className='btn btn-block'>Back to Home</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
