import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logoimage from '../../assets/images/rocket-loanz-logo-white.png';
import MenuBar from './menubar';

export default function Navbar() {
  const navigate = useNavigate();

  const [menu, setMenu] = useState(false)

  const handleOnClick = () => {
    setMenu( !menu )
  }
  const handleClose = () => {
    setMenu( false )
  }

  return (
    <div className='topNav navbar-expand-sm'>
      <div className='logo'>
        <figure onClick={handleClose}>
          <Link to={'/'} className='logo'><img src={logoimage} title='rocket loanz' /></Link>
        </figure>
      </div>
        <React.Fragment>
          <button 
              onClick={() => handleOnClick()}
              className={`navbar-toggler toggle-overlay ${menu ? "open-toggler" : ""}`}
              type="button" data-bs-toggle="collapse" 
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown" 
              aria-expanded="false" 
              aria-label="Toggle navigation">
              <span>
                  <span className="line"></span>
                  <span className="m-line"></span>
                  <span className="line"></span>
              </span>
          </button>
        </React.Fragment>
      { menu ? <MenuBar handleOnClick = { handleOnClick } /> : null }
      <ul className='navLinks'>
          <li className='homebuyer'><span>home buyers</span>
            <ul className='dropdown'>
              <li><span onClick={() => navigate(`/downpayments`, {state:["home downpayment finance"]})}>home downpayment finance</span></li>
              <li><span onClick={() => navigate(`/downpayments`, {state:["instant home loan eligibility"]})}>instant home loan eligibility</span></li>
            </ul>
          </li>
          <li><Link to={'/developers'}>developers</Link></li>
          <li><Link to={'/about'}>about</Link></li>
      </ul>
    </div>
  )
}  
