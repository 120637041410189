import React,{ useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Progress } from 'reactstrap';
import TextInput from '../../molecules/input'
import '../../../assets/Scss/register.css'
import { alphabatesWithSpace, isValidMobileNo, numberText } from '../../../helper/method';

const Registration = () => {
    const navigate = useNavigate();


    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [adharLinkNo, setAdharLink] = useState("")
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errEmail: "",
        errMobileno: ""
    })

    let hasError = false;

    const handleStateChange = (name,value) => {

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name,value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name,value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "adharLinkNo"){
            let check = value
            if(numberText(check)){
                setAdharLink(check)
                handleError(name,value)
            }
        }
    }

    const handleError = (name,value) => {
        
        console.log("handle OnBlure Change", name, "value", value)

        let allState = errors;

        if(name === "firstName"){
            if(!value.length){
                allState["errfName"] = "Enter your First name"
            }else if(value.length < 3){
                allState["errfName"] = "Name length should be 3 charector"
            }else{
                allState["errfName"] = ""
            }
        }
        if(name === "lastName"){
            if(!value.length){
                allState["errLName"] = "Enter your Last name"
            }else if(value.length < 3){
                allState["errLName"] = "Name length should be 3 charector"
            }else{
                allState["errLName"] = ""
            }
        }
        if(name === "email"){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.length){
                allState["errEmail"] = "Email address is required"
            }else if(!value.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
            }else{
                allState["errEmail"] = ""
            }
        }

        if(name === "adharLinkNo"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
        
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        let mobile = adharLinkNo.toString()
        
        if(!email.length){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!email.length){
                allState["errEmail"] = "Enter Email ID"
                hasError = true
            }else if(!email.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
                hasError = true
            }
        }
        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(adharLinkNo)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            alert("test")
            navigate("/projects")
        }
    }

    return (
        <div className='register'>
            <div className='container'>
                <div className='captions'>
                    <h2>welcome</h2>
                    <p>let's begin your home ownership journey!</p>
                </div>
                <form className='form'>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="firstName"
                                id={"firstName"}
                                value={firstName}
                                errorMSg={errors.errfName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"First Name (as per Aadhaar)"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="lastName"
                                id={"lastName"}
                                value={lastName}
                                errorMSg={errors.errLName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Last Name (as per Aadhaar)"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-12'>
                            <TextInput
                                name="email"
                                id={"email"}
                                value={email}
                                errorMSg={errors.errEmail}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Email"}
                                autoComplete={"off"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-12'>
                            <TextInput
                                name="adharLinkNo"
                                id={"adharLinkNo"}
                                value={adharLinkNo}
                                errorMSg={errors.errMobileno}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"tel"}
                                className={"form-control"}
                                placeholder={"Aadhaar - Linked Mobile"}
                                maxLength={"10"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group continue col-12'>
                            <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>Continue</button>
                        </div>
                    </div>
                    <div className='row pross'>
                        <div className='form-group col-12'>
                            <div className="progress">
                                <Progress value="0"></Progress>
                                <span>0%</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default Registration;