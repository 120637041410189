import React from 'react';
import { Link, useNavigate  } from 'react-router-dom'

const MenuBar = ({handleOnClick}) => {
    const navigate = useNavigate();
    const handleRoute = (value)=> {
        navigate(`/downpayments`, {state:[value]})
        handleOnClick();
    }
    return (
        <div className="openmenu">
            <ul className='menu-list'>
                <li className='homebuyer'>
                    <span>home buyers</span>
                    <ul className='dropdown'>
                    <li><span onClick={() => handleRoute("home downpayment finance")}>home downpayment finance</span></li>
                    <li><span onClick={() => handleRoute("instant home loan eligibility")}>instant home loan eligibility</span></li>
                    </ul>
                </li>
                <li><Link to={"/developers"} onClick={handleOnClick}>developers</Link></li>
                <li><Link to={"/about"} onClick={handleOnClick}>about</Link></li>
            </ul>
        </div>
    )
}

export default MenuBar;