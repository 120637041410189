import React,{ useState } from 'react'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from 'reactstrap';


const data = [
    {
        id : 1,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 2,
        lable : "Does availing the Home Down Payment Assistance Program affect my overall home loan eligibility?",
        value : "In ad velit in ex nostrud dolore cupidatat consectetur ea in ut nostrud velit in irure cillum tempor laboris sed adipisicing eu esse duis nulla non.",
    },
    {
        id : 3,
        lable : "Do I need to pledge any collateral with Rocket Loanz to avail credit assistance under Down Payment Assistance Program?",
        value : "In ad velit in ex nostrud dolore cupidatat consectetur ea in ut nostrud velit in irure cillum tempor laboris sed adipisicing eu esse duis nulla non.",
    },
    {
        id : 4,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 5,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 6,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 7,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 8,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 9,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 10,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
    {
        id : 11,
        lable : "Who will be the direct beneficiary of the Rocket Loanz down payment assistance amount?",
        value : "Home buyers will be the direct beneficiaries of the home down payment assistance amount. Since, the down payment assistance is a personal loan provided by us, the amount will be directly credited into the personal account of the Rocket Loanz client. Upon receiving the amount, the client will have to immediately pay the down payment assistance amount to the developers through cheque or electronic payment system.",
    },
]

export default function Faqs() {

    const [open, setOpen] = useState(1);

    const toggle = (id) => {
        if (open === id) {
        setOpen();
        } else {
        setOpen(id);
        }
    };

  return (
        <section className='faq_sec'>
            <div className='container'>
                <Accordion open={open} toggle={toggle}>
                {
                    data.length && data.map((item, index) => {
                    return <AccordionItem key={index}
                        className={`${item.id === open ? 'active' : ""}`}
                    >
                        <AccordionHeader targetId={item.id}>{item.lable}</AccordionHeader>
                            <AccordionBody accordionId={item.id}>
                                {item.value}
                            </AccordionBody>
                    </AccordionItem>
                    })
                }
                </Accordion>
            </div>
        </section>
    )
}
