import React from 'react';
import { useNavigate } from "react-router-dom";
import HomeBanner from '../../atom/HomeBanner';
import AboutRockets from './AboutRockets';
import CreditScore from './CreditScore';
import InstantHomeLoan from './InstantHomeLoan';
import '../../../assets/Scss/style.scss'
import Footer from '../../shared/Footer';


const Home = () => {

  const navigate = useNavigate();

  const handleRedirect = (title) =>{
    navigate(`/downpayments/`, {state:[title]})
  }
    return <div>
          <HomeBanner handleRedirect={handleRedirect} />
          <InstantHomeLoan handleRedirect={handleRedirect} />
          <AboutRockets />
    </div>;
}

export default Home;