import { put } from 'redux-saga/effects';
import emailApi from "../services/emailApi";
import { EMAIL_FAILED, EMAIL_RESPONSE } from '../redux/types/emailType';


let api = new emailApi();

export function* EmailSaga({ payload, resolve }) {
    try {
        let data = yield api.UserRequest(payload);
        if (data.status === 200) {
            yield put({ type: EMAIL_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            yield put({ type: EMAIL_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: EMAIL_FAILED, payload: e })

    }
}