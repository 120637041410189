import React,{useEffect} from 'react'
import { FaRegFilePdf } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function About() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section>
        <div className='container'>
            <div className='AboutSec'>
            <h3>about</h3>
            <h2>rocket loanz</h2>
            <p><b>Rocket Loanz</b> is a fintech company addressing the most pressing problem in the home purchase life cycle of the middle-class Indian - The shortage of downpayment while buying a home .</p>
            <p>Through its proprietary underwriting model, it provides instant support to the home buyer in paying the downpayment amount. This brings quick cashflow relief to the homebuyers and helps them buy their dream home.</p>
            <p>The company is promoted by Elysium Group whose flagship company, Elysium Capital, is one of the most respected real estate-focused investment banks in the country having arranged more than ₹ 10,000 crores of institutional finance for its clients.</p>
            </div>
        </div>
    </section>
  )
}
