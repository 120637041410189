import React from 'react';
import cibilGraph from '../../../assets/images/cibil-Graph.png';



export default function CreditScore() {
  return (
    <section className='creditScore crdscore'>
      <div className='container'>
        <figure>
          <span><img src={cibilGraph} /></span>
          <figcaption>
            <div className='container'>
              <h2 data-aos="fade-down" data-aos-duration="1500">get your <span>free</span>
                credit score</h2>
              <h4 data-aos="fade-down" data-aos-duration="1000">checking your credit score will not impact your score</h4>
              <a data-aos="zoom-in" data-aos-duration="1000" href='/Creditscore/CreditscoreLP' className='btn btn-primary'>Check now!</a>
            </div>
          </figcaption>
        </figure>
      </div>
    </section>
  )
}
