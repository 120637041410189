import { useState, useEffect } from 'react'
import '../../../assets/Scss/register.css'



export default function Thankyou() {

  return (
    <section>
        <div className='container'>
            <div className='innerSec'>
                <h5>Dear Kaushal Ranpura,</h5>
                <h4>thank you for applying with us</h4>
                <p>wait you will receive loan confirmation in 1 minute!</p>
                <CountDown />
            </div>
        </div>
    </section>
  )
}

const CountDown = () => {
    const [count, setCount] = useState(59)
    useEffect(() => {
        const timer = setInterval(() => {
            if(count !== 0){
                setCount( count - 1 )
            }
        }, 1000);

        return () => {
            clearInterval(timer)
        }

    }, [count])

    return <div className='wrapper'>
            <span className='counter'>00</span>
            <span  className='colon'>:</span>
            <span className='counter'>{count < 10 ? `0${count}` : `${count}` }</span>
        </div>
    }
