import React from 'react'
import loadable from '@loadable/component'
import PublicRoute from './hoc/Protected';

import { Route, Routes } from 'react-router-dom';
import Header from './shared/Header';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import DownPayment from './Pages/DownPayment';
import Registration from './Pages/Register/Registration';
import Verification from './Pages/Register/Verification';
import CreditScore from './Pages/Home/CreditScore';
import CreditscoreLP from './Pages/CreditscoreLP';
import About from './Pages/About';
import Developers from './Pages/Developers';
import ThankyouMessage from './Pages/ThankyouMessage';
import Faqs from './Pages/Faqs';
import HowItWorks from './Pages/HowItWorks';
import ContactUs from './Pages/Contactus';
import Projects from './Pages/Projects';
import Applicant from './Pages/Applicant/Applicant';
import CoApplicant from './Pages/Applicant/CoApplicant';
import ApprovedLoanOffer from './Pages/ApprovedLoanOffer';
import ProvisionalSanctionLetter from './Pages/ApplicationDetails/ProvisionalSanctionLetter'
import ApplicationDetails from './Pages/ApplicationDetails';
import ProjectDetails from './Pages/ApplicationDetails/ProjectDetails';
import FinancialDetails from './Pages/ApplicationDetails/FinancialDetails';
import EMICalculator from './Pages/EMICalculator/index';
import Downpaymennt from './Pages/Downpayments';
import Thankyou from './Pages/ApplicationDetails/Thankyou';

import '../assets/Scss/mediaStyle.scss'
import Footer from './shared/Footer';
  
// const Header = loadable(() => import('./shared/Header')) ;
// const Home = loadable(() => import('./Pages/Home')) ;
// const NotFound = loadable(() => import('./Pages/NotFound')) ;
// const DownPayment = loadable(() => import('./Pages/DownPayment')) ;
// const Registration = loadable(() => import('./Pages/Register/Registration')) ;
// const Verification = loadable(() => import('./Pages/Register/Verification')) ;
// const CreditScore = loadable(() => import('./Pages/Home/CreditScore')) ;
// const CreditscoreLP = loadable(() => import('./Pages/CreditscoreLP')) ;
// const About = loadable(() => import('./Pages/About')) ;
// const Developers = loadable(() => import('./Pages/Developers')) ;
// const ThankyouMessage = loadable(() => import('./Pages/ThankyouMessage')) ;
// const Faqs = loadable(() => import('./Pages/Faqs')) ;
// const HowItWorks = loadable(() => import('./Pages/HowItWorks')) ;
// const ContactUs = loadable(() => import('./Pages/Contactus')) ;
// const Projects = loadable(() => import('./Pages/Projects')) ;
// const Applicant = loadable(() => import('./Pages/Applicant/Applicant')) ;
// const CoApplicant = loadable(() => import('./Pages/Applicant/CoApplicant')) ;
// const ApprovedLoanOffer = loadable(() => import('./Pages/ApprovedLoanOffer')) ;
// const ProvisionalSanctionLetter = loadable(() => import('./Pages/ApplicationDetails/ProvisionalSanctionLetter'));
// const ApplicationDetails = loadable(() => import('./Pages/ApplicationDetails')) ;
// const ProjectDetails = loadable(() => import('./Pages/ApplicationDetails/ProjectDetails')) ;
// const FinancialDetails = loadable(() => import('./Pages/ApplicationDetails/FinancialDetails')) ;
// const EMICalculator = loadable(() => import('./Pages/EMICalculator')) ;
// const Downpayments = loadable(() => import('./Pages/Downpayments')) ;
// const Thankyou = loadable(() => import('./Pages/ApplicationDetails/Thankyou')) ;




const Router = () => {
  return (
    <React.Fragment>
        <Header />
        <PublicRoute component= {Home} path="/" exact />
        <PublicRoute component={DownPayment} path="/downPayment" exact />
        <PublicRoute component={Registration} path="/registration" exact />
        <PublicRoute component={Applicant} path="/applicant" exact />
        <PublicRoute component={CoApplicant} path="/co-applicant" exact />
        <PublicRoute component={Verification} path="/verification" exact />
        <PublicRoute component={CreditScore} path="/creditScore" exact />
        <PublicRoute component={CreditscoreLP} path="/Creditscore/CreditscoreLP" exact />
        <PublicRoute component={About} path="/about" exact />
        <PublicRoute component={Developers} path="/developers" exact />
        <PublicRoute component={ThankyouMessage} path="/thankyouMessage" exact />
        <PublicRoute component={Faqs} path="/faqs" exact />
        <PublicRoute component={HowItWorks} path="/howitworks" exact />
        <PublicRoute component={ContactUs} path="/contactus" exact />
        <PublicRoute component={Projects} path="/projects" exact />
        <PublicRoute component={ApprovedLoanOffer} path="/approvedloanoffer" exact />
        <PublicRoute component={ProvisionalSanctionLetter} path="/provisionalsanctionletter" exact />
        <PublicRoute component={EMICalculator} path="/emicalculator" exact />
        <PublicRoute component={ApplicationDetails} path="/application/details" exact />
        <PublicRoute component={ProjectDetails} path="/project/details" exact />
        <PublicRoute component={FinancialDetails} path="/financial/details" exact />
        <PublicRoute component={Downpaymennt} path="/downpayments" exact />
        <PublicRoute component={Thankyou} path="/thankyou" exact />
        {/* <Route element={<NotFound />} path="*" /> */}
        <Footer />
    </React.Fragment>
  )
}

export default Router;