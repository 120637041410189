import React,{useEffect} from 'react'
import HeroBanner from '../../assets/images/HomeHeroBanner.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';



export default function HomeBanner({handleRedirect}) {
  useEffect(() => {
    AOS.init({
      once: true,
      disable:'mobile'
    });
  }, [])
  return (
    <section className='heroBanner'>
       <figure>
       <img src={HeroBanner} />
       <figcaption>
            <h2 data-aos="fade-down" data-aos-duration="1500">short of downpayment <span>for your new home?</span></h2>
            <h3 data-aos="fade-down" data-aos-duration="1000">Take it at <span>0%</span> from us </h3>
            <button onClick={() => handleRedirect("home downpayment finance")} className='btn btn-primary'>Apply Now</button>
       </figcaption>
       </figure>
    </section>
  )
}
