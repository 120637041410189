import React, {useEffect} from 'react'
import Navbar from './Navbar'

export default function Header() {
  // Sticky Menu Area
  // useEffect(() => {
  //   window.addEventListener('scroll', isSticky);
  //   return () => {
  //       window.removeEventListener('scroll', isSticky);
  //   };
  // })
  // const isSticky = (e) => {
  //   const header = document.querySelector('.header-section');
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 78 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  // };
  return (
    <header className='header-section'> 
      <div className="container">
        <Navbar />
      </div>
    </header>
  )
}
