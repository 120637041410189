import React from 'react'

export default function ApprovedLoanOffer() {
  return (
    <div className='approvedloanoffer inner-pg'>
        <div className='container'>
            <h3>approved loan offer</h3>
            <div className='d-flex justify-content-between mb-5 mt-5'>
                <span>Application Number: 512</span>
                <span>28 Jun, 2022</span>
            </div>
            <h6><b>Dear Kaushal Ranpura,</b></h6>
            <p className='mt-5'>We are pleased to inform you that your downpayment home loan has been approved as per terms and conditions mentioned below:</p>
            <h4>loan details</h4>
            <div className='table-responsive table-bordered'>
                <table className='table tbl-style'>
                    <tbody>
                        <tr>
                            <td><label>Loan Amount</label></td>
                            <td>₹ 1,00,000</td>
                        </tr>
                        <tr>
                            <td><label>Applicant</label></td>
                            <td>Kaushal Ranpura</td>
                        </tr>
                        <tr>
                            <td><label>Co-applicant</label></td>
                            <td>Archana Ranpura</td>
                        </tr>
                        <tr>
                            <td><label>Rate of Interest</label></td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <td><label>Loan Tenure</label></td>
                            <td>12 Monthly Instalments</td>
                        </tr>
                        <tr>
                            <td><label>Installment</label></td>
                            <td>Monthly</td>
                        </tr>
                        <tr>
                            <td><label>Processing Fee</label></td>
                            <td>₹ 1,180 (999+GST)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 table-responsive'>
                <table className='table table-bordered tbl-style2'>
                    <thead>
                        <tr>
                            <th>1<sup>st</sup> - 4<sup>th</sup> Month</th>
                            <th>5<sup>th</sup> - 8<sup>th</sup> Month</th>
                            <th>9<sup>th</sup> - 12<sup>th</sup> Month</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>₹ 10,000</td>
                            <td>₹ 8,000</td>
                            <td>₹ 6,000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>Lorem ipsum dummy text ipsum dummy text ipsum dummy text ipsum dummy text.</p>
            <p><b>Terms and conditions</b></p>
            <ol>
                <li>Lorem ipsum dummy text Lorem ipsum dummy text.</li>
                <li>Lorem ipsum dummy text.</li>
                <li>Lorem ipsum dummy text Lorem ipsum dummy text Lorem ipsum dummy text.</li>
                <li>Lorem ipsum dummy text.</li>
                <li>Lorem ipsum dummy text Lorem ipsum dummy text.</li>
            </ol>
        </div>
    </div>
  )
}
