import React,{ useState, useEffect } from 'react';
import {useNavigate, useLocation } from "react-router-dom";
import TextInput from '../../molecules/input'
import '../../../assets/Scss/register.css'
import { alphabatesWithSpace, fetchWithWait, isValidMobileNo, numberText } from '../../../helper/method';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { sendEamilAction } from '../../../redux/actions/emailAction';
import { useDispatch } from 'react-redux';
import PageLaoder from '../../atom/loader/pageLaoder';


const Downpayments = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errMobileno: ""
    })

    let hasError = false;

    useEffect(() => {
        AOS.init({
          once: true,
        });
      }, [])

    const handleStateChange = (name,value) => {

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name,value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name,value)
            }
        }
        if(name === "mobileno"){
            let check = value
            if(numberText(check)){
                setMobileno(check)
                handleError(name,value)
            }
        }
    }

    const handleError = (name,value) => {
        
        console.log("handle OnBlure Change", name, "value", value)

        let allState = errors;

        if(name === "firstName"){
            if(!value.length){
                allState["errfName"] = "Enter your First name"
            }else if(value.length < 3){
                allState["errfName"] = "Name length should be 3 charector"
            }else{
                allState["errfName"] = ""
            }
        }
        if(name === "lastName"){
            if(!value.length){
                allState["errLName"] = "Enter your Last name"
            }else if(value.length < 3){
                allState["errLName"] = "Name length should be 3 charector"
            }else{
                allState["errLName"] = ""
            }
        }
        if(name === "mobileno"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
        
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        let mobile = mobileno.toString()
        
        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(mobile)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        let data = {
            "firstname": firstName,
            "lastname": lastName,
            "mobileno": mobileno,
        }

        if(!checkVailidation()){
            setLoading(true)
            fetchWithWait({ dispatch, action: sendEamilAction(data) }).then((res) => {
            if(res.status === 200 ){
                setLoading(false)
                navigate("/thankyouMessage", {state: data})
            }
            }).catch((e) => {
                console.log(`error`, e)
            })
        }
    }


    const { state } = location

    return (
        <div className='register typ2'>
            <div className='container'>
                <div className='captions' data-aos="fade-down" data-aos-duration="1500">
                    {state.length ? <h2>{state[0]}</h2> : null }
                    <p>please enter your details</p>
                </div>
                <form className='form' data-aos="fade-down" data-aos-duration="1500">
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="firstName"
                                id={"firstName"}
                                value={firstName}
                                errorMSg={errors.errfName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"First Name"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="lastName"
                                id={"lastName"}
                                value={lastName}
                                errorMSg={errors.errLName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Last Name"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-12'>
                            <TextInput
                                name="mobileno"
                                id={"mobileno"}
                                value={mobileno}
                                errorMSg={errors.errMobileno}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"tel"}
                                className={"form-control"}
                                placeholder={"Mobile number"}
                                maxLength={"10"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group continue col-12'>
                            <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            {loading &&
            <PageLaoder />
        }
        </div>
    );
}


export default Downpayments;