import React from 'react'
import { FaRegFilePdf } from 'react-icons/fa';

export default function AboutRockets() {
  return (
    <section className='about_sec'>
        <figcaption>
            <div className='container'>
               <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <h2 data-aos="fade-down" data-aos-duration="1500">about <span>rocket loanz</span></h2>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <p data-aos="fade-down" data-aos-duration="1500">Rocket Loanz is a fintech company addressing the most pressing problem in the home purchase life cycle of the middle-class Indian – The shortage of downpayment amount while taking a home loan. </p>
                    <p data-aos="fade-down" data-aos-duration="1000">Through its proprietary underwriting model, it provides instant support to the home buyer in paying the down payment amount. This brings quick cashflow relief to the homebuyers and helps them buy their dream home.</p>
                    <a data-aos="zoom-in" data-aos-duration="1000" href='/About' className='btn btn-primary'>Know More</a>
                </div>
            </div>
            
        </div>
        </figcaption>
    </section>
  )
}
