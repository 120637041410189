import * as type from '../types/emailType';

const initialState = {
    email : [],
}

const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.EMAIL_RESPONSE :{
            return {...state, email: action.payload };
        }
        case type.EMAIL_FAILED :{
            return {...state, email: action.payload };
        }
        default:
            return state
    }
}

export default emailReducer;
