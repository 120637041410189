import React,{ useState } from 'react';
import { CgClose } from "react-icons/cg";
import '../../assets/Scss/register.scss'

const AadharVerification = (props) => {

    const [code, setCode] = useState("")
    const [code1, setCode1] = useState("")
    const [code2, setCode2] = useState("")
    const [code3, setCode3] = useState("")
    

    const handleStateChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if(name === "code"){
            setCode(value)
        }
        if(name === "code1"){
            setCode1(value)
        }
        if(name === "code2"){
            setCode2(value)
        }
        if(name === "code3"){
            setCode3(value)
        }
    }

    return (
        <div className='verification-modal'>
            <div className='over-layout'>
                <span className='modal-close' onClick={props.handleCloseModal}><CgClose /></span>
                <div className='captions'>
                    <h2>aadhaar verification</h2>
                    <p>enter OTP sent on your mobile</p>
                </div>
                <form className='form'>
                    <div className='row'>
                        <div className='form-group col-12'>
                            <input 
                                placeholder=''
                                onChange={handleStateChange}
                                maxLength="1"
                                name="code"
                                value={code}
                            />
                            <input 
                                placeholder=''
                                onChange={handleStateChange}
                                maxLength="1"
                                name="code1"
                                value={code1}
                            />
                            <input 
                                placeholder=''
                                onChange={handleStateChange}
                                maxLength="1"
                                name="code2"
                                value={code2}
                            />
                            <input 
                                placeholder=''
                                onChange={handleStateChange}
                                maxLength="1"
                                name="code3"
                                value={code3}
                            />
                            {/* <span className="text-danger">{errfName}</span> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-12'>
                            <button type='submit' className='btn btn-block'>Verify</button>
                        </div>
                    </div>

                    <div className='resendOTP'>Resend Otp</div>
                </form>
            </div>
        </div>
    );
}


export default AadharVerification;