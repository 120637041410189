import React from 'react';
import { Routes, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {

  return (
        <Routes>
            <Route {...rest} element={
                <div>
                    <Component />
                </div>
            } />
        </Routes>
    );
};

export default PublicRoute;