import React from 'react';
import Slider from "react-slick";
// import {
//     BrowserView,
//     MobileView,
//     isBrowser,
//     isMobile
// } from "react-device-detect";
// import "slick-/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./project_slider.css"


const SlidSlick = (props) => {

    let showCount = 1;
    let slideScrol = 1;
    // if(isMobile){

    if (window.innerWidth <= 767) {
        showCount = 1;
        slideScrol = 1;
    } else if (window.innerWidth <= 1024) {
        if (props.banner === true) {
            showCount = 1;
            slideScrol = 1;
        } else {
            showCount = 2;
            slideScrol = 2;
        }

    } else {
        showCount = props.slidesToShow
        slideScrol = props.slidesToScrol
    }
    const settings = {
        //arrows: props.arrowHide!==undefined?props.arrowHide:false,
        slidesToShow: showCount,
        slidesToScroll: slideScrol,
        centerMode: props.center !== undefined ? props.center : false,

        // slidesToShow: showCount,
        // slidesToScrol:slideScrol,
        // arrows: props.arrow ? props.arrow : false,
        arrows: true,
        swipeToSlide: false,
        focusOnSelect: false,
        name: props.id,
        autoplay: false,
        id: props.id,
        infinite: true,
        dots: props.dots !== undefined ? props.dots : false,
        // dots: props.dots,
        // dotsClass: 'button__bar',
        // responsive: [
        //     {
        //       breakpoint: 1024,
        //       settings: {
        //         slidesToShow: 3,
        //         slidesToScroll: 3,
        //         infinite: true,
        //         dots: true
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 2,
        //         slidesToScroll: 2,
        //         initialSlide: 2
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
    };
    return (
        <Slider
            {...settings}
        >
            {props.children}
        </Slider>
    )
}
export default SlidSlick