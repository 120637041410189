import React from 'react'
import InstantHomeLoanImg from '../../../assets/images/InstantHomeLoan.jpg';
import InstantHomeLoanMobile from '../../../assets/images/InstantHomeLoanMobile.jpg';

export default function InstantHomeLoan({handleRedirect}) {
  return (
    <section className='instantHL'>
        <figure>
            <img className='desktop-purpose' src={InstantHomeLoanImg} />
            <img className='mobile-purpose' src={InstantHomeLoanMobile} />
            <figcaption>
                <div className='container'>
                <h2 data-aos="fade-down" data-aos-duration="1500">instant home loan eligibility</h2>
                <h5 data-aos="fade-down" data-aos-duration="500">get eligibility in 1 minute!</h5>
                <button
                className='btn btn-primary' onClick={() => handleRedirect("instant home Loan")}>Check  Now!</button>
                </div>
            </figcaption>
        </figure>
    </section>
  )
}
