import React,{ useState } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextInput from '../../molecules/input'
import '../../../assets/Scss/register.css'
import './style.scss'
import { alphabatesWithSpace, alphaNumeric, isValidMobileNo, numberText } from '../../../helper/method';
import { Progress } from 'reactstrap';

const ApplicationDetails = () => {
    const navigate = useNavigate();


    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [adharLinkNo, setAdharLink] = useState("")
    const [panno, setPanno] = useState("")
    const [dob, setDob] = useState(null)
    const [selectGender, setSelectGender] = useState(null)
    const [errors, setErrors] = useState({
        errfName: "",
        errLName: "",
        errMobileno: "",
        errPan: "",
    })

    let hasError = false;


    const setSelectedDate = (data) => {
        // let getdata = moment(data, 'DD-MM-YYYY').format("DD-MM-YYYY")
        // let date =  getdata.toString()
        setDob(data);
    }


    const handleStateChange = (name,value) => {

        if(name === "firstName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setFirstName(check)
                handleError(name,value)
            }
        }
        if(name === "lastName"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLastName(check)
                handleError(name,value)
            }
        }
        
        if(name === "adharLinkNo"){
            let check = value
            if(numberText(check)){
                setAdharLink(check)
                handleError(name,value)
            }
        }
        if(name === "panno"){
            let check =  value.toUpperCase();
            if(alphaNumeric(check)){
                setPanno(check)
                handleError(name, value)
            }
        }
    }

    const handleError = (name,value) => {
        
        console.log("handle OnBlure Change", name, "value", value)

        let allState = errors;

        if(name === "firstName"){
            if(!value.length){
                allState["errfName"] = "Enter your First name"
            }else if(value.length < 3){
                allState["errfName"] = "Name length should be 3 charector"
            }else{
                allState["errfName"] = ""
            }
        }
        if(name === "lastName"){
            if(!value.length){
                allState["errLName"] = "Enter your Last name"
            }else if(value.length < 3){
                allState["errLName"] = "Name length should be 3 charector"
            }else{
                allState["errLName"] = ""
            }
        }
        if(name === "panno"){
            if(!value.length){
                allState["errPan"] = "Pan no is required"
            }else if(value.length < 10 ){
                allState["errPan"] = "Enter a valid Pan no"
            }else{
                allState["errPan"] = ""
            }
        }
        if(name === "adharLinkNo"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
        
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        let mobile = adharLinkNo.toString()
        
        if(!panno.length){
            allState["errPan"] = "Pan no required"
            hasError = true
        }else if(panno.length < 10){
            allState["errPan"] = "Enter a valid pan no"
            hasError = true
        }
        if(!firstName.length){
            allState["errfName"] = "Enter your first name"
            hasError = true
        }else if(firstName.length < 3){
            allState["errfName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lastName.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lastName.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(adharLinkNo)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }


    const handeleFormSubmit = (e) => {
        e.preventDefault();
        if(!checkVailidation()){
            alert("test")
            navigate("/verification")
        }
    }

    return (
        <div className='register project'>
            <div className='container'>
                <div className='captions'>
                    <h2>Welcome</h2>
                    <p>let’s begin your journey!</p>
                </div>
                <form className='form'>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="firstName"
                                id={"firstName"}
                                value={firstName}
                                errorMSg={errors.errfName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"First Name (as per Aadhaar)"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="lastName"
                                id={"lastName"}
                                value={lastName}
                                errorMSg={errors.errLName}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Last Name (as per Aadhaar)"}
                            />
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="adharLinkNo"
                                id={"adharLinkNo"}
                                value={adharLinkNo}
                                errorMSg={errors.errMobileno}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"tel"}
                                className={"form-control"}
                                placeholder={"Mobile"}
                                maxLength={"10"}
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <TextInput
                                name="panno"
                                id={"panno"}
                                value={panno}
                                errorMSg={errors.errPan}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                type={"text"}
                                className={"form-control"}
                                placeholder={"Pan no"}
                                maxLength={"10"}
                                autoComplete={"off"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 col-sm-12'>
                            <DatePicker
                                selected={dob}
                                onChange={date => setSelectedDate(date)}
                                dateFormat = {"dd/MM/yyyy"}
                                placeholderText={'dd/mm/yyyy'}
                                className ="txtbox"
                                // showYearDropdown // year show and scrolldown alos
                                // scrollableYearDropdown
                            />
                        </div>
                        <div className='form-group col-md-6 col-sm-12'>
                            <select className='texbox'>
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-8 col-sm-12 save-button'>
                            <button type='submit' className='btn btn-block' onClick={handeleFormSubmit}>Continue</button>
                        </div>
                    </div>
                    <div className='row save-button'>
                        <div className='form-group col-md-12 col-sm-12'>
                            <div className="progress">
                                <Progress value="100"></Progress>
                                <span>100%</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default ApplicationDetails;